import React, { Component } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import history from './utils/history';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const PrivateLayout = React.lazy(() => import('./components/layouts/PrivateLayout/layout'));

// Pages
const Login = React.lazy(() => import('./pages/auth/LoginPage'));
const NewPassword = React.lazy(() => import('./pages/auth/NewPassword'));
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword'));

class App extends Component {

  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/new-password" name="Set Password" render={props => <NewPassword {...props} />} />
            <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
            <Route path="/" name="Private" render={props => <PrivateLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;

