import axios from "axios";
import { getToken } from "./localStorage";
import toast from "./toast";
import history from "./history";
import { env } from "react-env-config";

export default function axiosSetup () {
  axios.defaults.baseURL = env("API_URL");

  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      const token = getToken(); // get token

      if (token) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
      }

      config.headers["content-type"] = `application/json`;

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.message === "Network Error") {
        toast.warn("Network Error", "Unable to connect");
      }

      if (error.response && error.response.status === 401) {
        history.push("/login");
        toast.warn("Unauthorized", "Access is denied. Try to Login");
      } else if (error.response && error.response.status === 422) {
        if (Array.isArray(error.response.data.message)) {
          const errors = error.response.data.message.map((txt) => (
            <label className="text-capitalize">{txt}</label>
          ));
          toast.error("Validation Error", errors);
        } else {
          toast.error("Validation Error", error.response.data.message);
        }
      } else {
        // Do something with request error
        return Promise.reject(error);
      }
    }
  );

  return axios;
}
