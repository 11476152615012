import { combineReducers } from 'redux';

import auth from './auth.reducer';
import sidebar from './sidebar.reducer';

const reducer = () => combineReducers({
    auth,
    sidebar
})

export default reducer;