const initalState = {
    isAuthenticated: false,
    user: null
}

export default function auth (state = initalState, { type, ...payload }) {
    switch (type) {
        case 'LOGIN':
            return {
                isAuthenticated: true,
                user: payload.user
            };
        case 'LOGOUT':
            return { 
                isAuthenticated: false,
                user: null 
            };
        default:
            return state
    }
}