import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import rootReducer from './reducers';
import { loadState, saveState } from './utils/localStorage';

export default function configureAppStore() {
    const persistedState = loadState();

    const store = configureStore({
        reducer: rootReducer(),
        preloadedState: persistedState,
        middleware: [...getDefaultMiddleware()],
        enhancers: []
    })

    store.subscribe(throttle(() => {
      saveState(store.getState())
    }, 1000))

  return store
}