import { NotificationManager } from "react-notifications";

const clearAll = () => {
  return NotificationManager.listNotify.forEach((n) =>
    NotificationManager.remove({ id: n.id })
  );
};

const toast ={
  success: (message, title) => {
    clearAll();
    NotificationManager.success(message, title, 3000);
  },
  error: (title, message) => {
    clearAll();
    NotificationManager.error(message, title, 5000);
  },
  warn: (title, message) => {
    clearAll();
    NotificationManager.warning(message, title, 3000);
  },
  info: (title, message) => {
    clearAll();
    NotificationManager.info(message, title, 3000);
  },
};

export default toast;
