import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';

import App from "./App";
import configureAppStore from './configureAppStore'
import { icons } from './assets/icons';
import axiosSetup from './utils/setup-axios';
 
// import css
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-notifications/lib/notifications.css';
import "./index.css";

// create redux store
const store = configureAppStore()

// call the axios setup
axiosSetup()

React.icons = icons

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationContainer/>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
